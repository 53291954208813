@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

div.status-error {
  color: #d13212;
}

div.status-medium {
  color: #eb5f07;
}

div.status-low {
  color: #ff9900;
}

div.status-success {
  color: awsui.$color-text-status-success;
}
